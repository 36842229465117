import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Box,
  Button,
  Flex,
  Heading,
  useColorModeValue,
  Text,
  InputGroup,
  InputRightElement,
  Icon,
} from "@chakra-ui/react"
import CenteredAuth from './components/centered';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { useGoogleLogin } from '@react-oauth/google';

const authenticateUrl = "https://appwise-functions.azurewebsites.net/api/login-user-authentication?code=IX2QBmJbwd6wOJhYZsycF1oki6hGnGmbomDo8DADoyXtAzFuwtlG8w%3D%3D";
const oauthCodeExchangeUrl = "https://appwise-functions.azurewebsites.net/api/login-google-code?code=lQAmzs_A1XKlGW5EIuBo88LrOXFMQtI-PYK_r_B8RhqnAzFuBPDC5w%3D%3D";

function LoginPage() {

  const handleLoginMS = () => window.location.replace("/ms-login");
  const googleOnSuccess = (response: any) => {
    console.log(response);
    fetch(oauthCodeExchangeUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ code: response.code })
    })
      .then(res => res.json())
      .then(response => {
        if (response) {
          localStorage.setItem("accessToken", response.loggedUser.accessToken);
          localStorage.setItem("wiseCompanyId", response.loggedUser.wiseCompanyId);
          localStorage.setItem("loggedUserName", response.loggedUser.userFullName);
          localStorage.setItem("loggedWithGoogle", response.loggedUser.email);
          localStorage.setItem("lastFetch", String(new Date()));
          window.location.replace("/");
        } else {
          localStorage.clear();
          alert("Failure trying to link google account to InWise user.");
        }
      });
  };

  const googleLogin = useGoogleLogin({
    onSuccess: googleOnSuccess,
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/userinfo.email',
  });

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleLogin = () => {
    console.log(`sending request to authenticate login '${email}'`)
    fetch(authenticateUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ login: email, password })
    })
      .then(res => res.json())
      .then(authResponse => {
        console.log(authResponse);
        if (authResponse.authResult === true) {
          localStorage.setItem("accessToken", authResponse.loggedUser.accessToken);
          localStorage.setItem("wiseCompanyId", authResponse.loggedUser.wiseCompanyId);
          localStorage.setItem("loggedUserName", authResponse.loggedUser.userFullName);
          window.location.replace("/");
        } else {
          localStorage.clear();
          alert("wrong credentials");
        }
      })
      .catch((error: Error) => {
        console.log(error);
      })
  }
  const authBg = useColorModeValue('white', 'navy.900');
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  return (
    <Box
      bg={authBg}
      float='right'
      minHeight='100vh'
      height='100%'
      position='relative'
      w='100%'
      transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
      transitionDuration='.2s, .2s, .35s'
      transitionProperty='top, bottom, width'
      transitionTimingFunction='linear, linear, ease'>
      <CenteredAuth
        image={'linear-gradient(135deg, #FFCF86 0%, #FFB018 100%)'}
        cardTop={{ base: '140px', md: '14vh' }}
        cardBottom={{ base: '50px', lg: 'auto' }}
        mx='0px'>
        <Flex
          maxW={{ base: '100%', md: 'max-content' }}
          w='100%'
          mx={{ base: 'auto', lg: '0px' }}
          me='auto'
          justifyContent='center'
          px={{ base: '20px', md: '0px' }}
          flexDirection='column'>
          <Box me='auto'>
            <Heading color={textColor} fontSize='36px' mb='10px'>
              Sign In
            </Heading>
          </Box>
          <Flex
            zIndex='2'
            direction='column'
            w={{ base: '100%', md: '420px' }}
            maxW='100%'
            background='transparent'
            borderRadius='15px'
            mx={{ base: 'auto', lg: 'unset' }}
            me='auto'
            mb={{ base: '20px', md: 'auto' }}>
            <FormControl>
              <FormLabel display='flex' ms='4px' fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='outline'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                type='email'
                placeholder='mail@inwise.ai'
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={e => setEmail(e.target.value)}
              />
              <FormLabel ms='4px' fontSize='sm' fontWeight='500' color={textColor} display='flex'>
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  isRequired={true}
                  fontSize='sm'
                  ms={{ base: '0px', md: '4px' }}
                  placeholder='Min. 8 characters'
                  mb='24px'
                  size='lg'
                  type={show ? 'text' : 'password'}
                  variant='outline'
                  onChange={e => setPassword(e.target.value)}
                />
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: 'pointer' }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              <Button fontSize='sm' variant='brand' fontWeight='500' w='100%' h='50' mb='24px' onClick={handleLogin}>
                Sign In
              </Button>
              <Button fontSize='sm' variant='brand' fontWeight='500' w='100%' h='50' mb='24px' onClick={() => googleLogin()}>
                Sign in with Google
              </Button>
              <Button fontSize='sm' variant='brand' fontWeight='500' w='100%' h='50' mb='24px' onClick={() => handleLoginMS()}>Sign in with Microsoft</Button>
            </FormControl>
          </Flex>
        </Flex>
      </CenteredAuth>
    </Box>
  );
}

export default LoginPage;
