import { Flex, Icon, ListItem, useColorModeValue, Text, useDisclosure, } from '@chakra-ui/react';
import { ThumbsDownIcon, ThumbsUpIcon, } from 'components/icons/Icons';
import { useEffect, useState } from 'react';
import { Insight } from '../../views/deals/Deal'
import { FeedbackPopup } from './FeedbackPopup';

const giveInsightFeedbackURL = "https://inwise-node-functions2.azurewebsites.net/api/insight-feedback?code=DXTF-i51c1ShayaTfMghLtQNeuTpdARguw1DJcGc8ifvAzFulfv-CA%3D%3D"

export function InsightFeedbackContainer(props: {
  insight: Insight,
  isPopupOpen: boolean,
  onPopupToggle: (isOpen: boolean) => void,
}) {
  const { insight, isPopupOpen, onPopupToggle, ...rest } = props;
  const [isActive, setIsActive] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const { isOpen, onToggle, onClose } = useDisclosure();

  const handleMouseEnter = () => {
    !isPopupOpen && setIsHovered(true);
  };

  const handleMouseLeave = () => {
    !isPopupOpen && setIsHovered(false);
  };

  useEffect(() => {
    onPopupToggle(isOpen);

  }, [isOpen]);

  const handleLikeButtonClicked = async () => {
    try {
      const response = await fetch(giveInsightFeedbackURL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ wiseCompanyId: localStorage.getItem("wiseCompanyId"), insightId: insight.insightId, isPositive: true }),
      });
      if (!response.ok) {
        console.error('Feedback successful');
        return;
      }
    }
    catch (error) { console.error('Error during fetch:', error) }
  }
  const handleDislikeButtonClicked = async () => {
    onToggle();
    try {
      const response = await fetch(giveInsightFeedbackURL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ wiseCompanyId: localStorage.getItem("wiseCompanyId"), insightId: insight.insightId, isPositive: false }),
      });
      if (!response.ok) {
        console.error('Feedback successful');
        return;
      }
    }
    catch (error) { console.error('Error during fetch:', error) }
  }

  const textColorLight = useColorModeValue('#c7cee2', 'white');
  const textColorTertiary = useColorModeValue('#A3AED0', 'white');

  //TODO: make it not this way
  const defineInsightContent = (insight: Insight) => {
    let content;
    const item = insight.content.item;
    if (item) {
      if (insight.content.owner) {
        content =
          <Flex flexDir={'column'} w='100%'>
            <Text><Text as="strong">{insight.content.owner}</Text> to {item.charAt(0).toLowerCase() + item.slice(1)}</Text>
            <Text w={'100%'} align={'end'} color={'secondaryGray.700'} fontWeight='bold' fontSize={'small'}>{insight.content.deadline ? `By ${insight.content.deadline.toLowerCase()}` : 'No deadline defined'}</Text>
          </Flex >
      } else if (item.substr(-16) === 'to be calculated') {
        const insightString = item.slice(0, -16).replace(/([A-Z])/g, ' $1').toLowerCase().trim();
        content = <Text> <Text as="strong">{insightString}</Text>{item.slice(-16)}</Text>
      } else return null;
    } else {
      content = <Text>{typeof insight.content === 'string' ? insight.content : item}</Text>;
    }
    return <ListItem key={insight.insightId} ml={2} w={'100%'}>{content}</ListItem>
  }


  if (!insight || !insight.content) return null;
  return (
    <>
      {isActive && <Flex
        flexDir={'row'}
        flexGrow={1}
        flexBasis={0}
        mr='32px'
        justifyContent={'space-between'}
        {...rest}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        position="relative"
        bg={isOpen ? "#ECECEC" : 'transparent'}
        w={'100%'}
      >
        {defineInsightContent(insight)}
        <Flex position="relative">
          <FeedbackPopup insightId={insight.insightId} isOpen={isOpen} onClose={onClose} onToggle={onToggle} onDismissed={() => setIsActive(false)} />
          {isHovered && !isOpen && (
            <Flex position={'absolute'} zIndex={'10'} right={'0px'}>
              <Flex
                as="button"
                onClick={handleLikeButtonClicked}
                color={textColorLight}
                _hover={{ color: textColorTertiary }}
                mx='2px' bg={'white'} borderRadius={'50%'} p={'8px'} boxShadow={'0 4px 8px 0 rgba(0,0,0,0.3)'}
              >
                <Icon as={ThumbsUpIcon} w="20px" h="20px" />
              </Flex>
              <Flex
                as="button"
                onClick={handleDislikeButtonClicked}
                color={textColorLight}
                _hover={{ color: textColorTertiary }}
                mx='2px' bg={'white'} borderRadius={'50%'} p={'8px'} boxShadow={'0 4px 8px 0 rgba(0,0,0,0.3)'}
              >
                <Icon as={ThumbsDownIcon} w="20px" h="20px" />
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>}
    </>
  );
}
