import { Flex, Text, useColorModeValue, UnorderedList, ListItem, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Accordion } from '@chakra-ui/react';
import Card from 'components/card/Card';
import InsightSectionWithFeedback from 'components/feedback/InsightSectionWithFeedback';
import { ActionItem } from 'views/deals/Deal';

export default function ActionItems(props: {
  header?: string,
  actionItems: any,
  variant?: string,
  [x: string]: any
}) {
  const { actionItems, variant, header, ...rest } = props;
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorTertiary = useColorModeValue('#A3AED0', 'white');
  console.log(actionItems);
  if (!actionItems) return null;

  if (variant === 'recommended') {
    const defineContent = (item: any) => {
      if (item.substr(-15) === 'to be discussed') {
        const insightString = item.slice(0, -16).replace(/([A-Z])/g, ' $1').toLowerCase().trim();
        const content = <Text> <Text as="strong">{insightString.charAt(0).toUpperCase() + insightString.slice(1)}</Text>{item.slice(-16)}</Text>
        return <ListItem ml={2}>{content}</ListItem>
      }
    }
    return (
      <Card mt='20px' p='20px'>
        <Flex flexDir={'column'} flexGrow={1} flexBasis={0} mr='32px'>
          <Flex align='center' textAlign='center' pb='10px'>
            <Text fontWeight={'bold'} color={textColor}>Recommended Action Items</Text>
          </Flex>
          <UnorderedList fontSize={'16px'}>
            {actionItems.map((ai: string) => (
              defineContent(ai)))
            }
          </UnorderedList>
        </Flex>
      </Card>
    )
  }


  const parseDeadline = (deadline: string | null): Date | null => {
    return deadline ? new Date(deadline) : null;
  };

  const now = new Date();
  const upcomingActionItems: any[] = [];
  const pastActionItems: any[] = [];

  actionItems.forEach((item: any) => {
    const deadline = parseDeadline(item.content.deadline);
    if (deadline === null || deadline >= now) {
      upcomingActionItems.push(item);
    } else {
      pastActionItems.push(item);
    }
  });

  upcomingActionItems.sort((a, b) => {
    const deadlineA = parseDeadline(a.content.deadline);
    const deadlineB = parseDeadline(b.content.deadline);
    if (deadlineA === null && deadlineB === null) return 0;
    if (deadlineA === null) return 1;
    if (deadlineB === null) return -1;
    return deadlineA.getTime() - deadlineB.getTime();
  });

  // Sort past deadlines by the deadline date (ascending)
  pastActionItems.sort((a, b) => {
    const deadlineA = parseDeadline(a.content.deadline);
    const deadlineB = parseDeadline(b.content.deadline);
    if (deadlineA && deadlineB) {
      return deadlineA.getTime() - deadlineB.getTime();
    }
    return 0;
  });
  if (upcomingActionItems.length === 0 && pastActionItems.length === 0) {
    return null;
  }

  return (
    <Card mt='20px' p='20px'>
      <InsightSectionWithFeedback
        insights={upcomingActionItems}
        sectionHeader={header}
        {...rest}
      />
      {pastActionItems && pastActionItems.length > 0 &&
        <Accordion allowMultiple>
          <AccordionItem key={'0'} borderBottomColor={'transparent'} mt={2}>
            <AccordionButton pl={'8px'}>
              <Box as='span' flex='1' textAlign='left'>
                <Text fontWeight={'bold'} color={textColorTertiary} fontSize={'sm'}>{`Completed (${pastActionItems.length})`}</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} p={0}>
              <InsightSectionWithFeedback
                insights={pastActionItems}
                sectionHeader={null}
                {...rest}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>}
    </Card>
  );
}
