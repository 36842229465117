import { useState, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Portal, Box, useDisclosure } from '@chakra-ui/react';

import routes from 'routes';
import FooterAdmin from 'components/footer/FooterAdmin';
import Navbar from 'components/navbar/NavbarAdmin';
import Sidebar from 'components/sidebar/Sidebar';
import { SidebarContext } from 'contexts/SidebarContext';
import DealsOverview from 'views/deals/overview';
import DealsCreate from 'views/deals/create';
import { DealSummary } from 'views/deals/Deal';
import ProspectCreate from 'views/prospects/create';
import ProspectOverview from 'views/prospects/overview';
import MeetingsOverview from 'views/meeting';
import AccountsOverview from 'views/accounts/overview';

export default function Frame(props: { [x: string]: any }) {
  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const [dealSummaries, onDealSummariesUpdated] = useState();
  const location = useLocation<{ dealSummary: DealSummary, routeName: string }>();
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== '/full-screen-maps';
  };
  const getActiveRoute = (routes: RoutesType[]): string => {
    if (location?.state?.routeName) {
      return location.state.routeName;
    }
    let activeRoute;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes: RoutesType[]): boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else {
        if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((route: RoutesType, key: number) => {
      if (route.layout === '/') {
        return <Route path={route.layout + route.path} component={route.component} key={key} />;
      }
      if (route.collapse) {
        return getRoutes(route.items);
      } else {
        return null;
      }
    });
  };

  const { pathname } = useLocation();
  const [brandText, setBrandText] = useState();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  document.documentElement.dir = 'ltr';
  const { onOpen } = useDisclosure();
  //TODO(KRIS) : should move title out to here
  return (
    <SidebarContext.Provider
      value={{
        brandText,
        setBrandText
      }}>
      <Sidebar routes={routes} display='none' dealSummaries={dealSummaries} onDealSummariesUpdated={onDealSummariesUpdated} {...rest} />
      <Box
        float='right'
        minHeight='100vh'
        height='100%'
        position='relative'
        maxHeight='100%'
        w={{ base: '100%', xl: 'calc( 100% - 230px )' }}
        maxWidth={{ base: '100%', xl: 'calc( 100% - 230px )' }}
        transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
        transitionDuration='.2s, .2s, .35s'
        transitionProperty='top, bottom, width'
        transitionTimingFunction='linear, linear, ease' px='0px'>
        <Portal>
          <Box>
            <Navbar
              onOpen={onOpen}
              logoText={'inWise AI'}
              brandText={getActiveRoute(routes) ?? brandText}
              secondary={getActiveNavbar(routes)}
              fixed={fixed}
              {...rest}
            />
          </Box>
        </Portal>

        {getRoute() ?
          <Box mx='auto' p={{ base: '20px', md: '30px' }} pe='20px' minH='calc(100vh - 85px)' pt='50px'>
            <Switch>
              <Route path='/deals/overview/:dealId' component={DealsOverview} />
              <Route path='/deals/create' component={DealsCreate} />
              <Route path='/accounts/overview/:accountId' component={AccountsOverview} />
              <Route path='/prospects/create' component={ProspectCreate} />
              <Route path='/prospects/overview/:prospectId' component={ProspectOverview} />
              <Route path='/meetings/overview/:meetingId' component={MeetingsOverview} />
              {getRoutes(routes)}
              <Redirect from='/' to='/deals/dashboard' />
            </Switch>
          </Box>
          : null
        }
        <Box>
          <FooterAdmin />
        </Box>
      </Box>
    </SidebarContext.Provider>
  );
}
