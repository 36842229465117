import {
  Avatar,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { SidebarResponsive } from 'components/sidebar/Sidebar';
import routes from 'routes';

function handleDelete() {
  window.location.replace("/settings/delete");
}

function handleLogout() {
  localStorage.clear();
  window.location.replace("/");
}

export default function NavbarLinksAdmin(props: { secondary: boolean }) {
  const { secondary } = props;
  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const shadow = useColorModeValue(
    '0 4px 8px 0px rgba(0, 0, 0, 0.3)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );
  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems='center'
      flexDirection='row'
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p='10px'
      borderRadius='30px'
      boxShadow={shadow}>
      <SidebarResponsive routes={routes} />

      <Menu>
        <MenuButton p='0px'>
          <Avatar
            _hover={{ cursor: 'pointer' }}
            color='white'
            name={localStorage.getItem("loggedUserName")}
            bg='#0F738C'
            size='sm'
            w='40px'
            h='40px'
          />
        </MenuButton>
        <MenuList boxShadow={shadow} p='0px' mt='10px' borderRadius='20px' bg={menuBg} border='none'>
          <Flex w='100%' mb='0px'>
            <Text
              ps='20px'
              pt='16px'
              pb='10px'
              w='100%'
              borderBottom='1px solid'
              borderColor={borderColor}
              fontSize='sm'
              fontWeight='700'
              color={textColor}>
              {localStorage.getItem("loggedUserName")}
            </Text>
          </Flex>
          <Flex flexDirection='column' p='10px'>
            <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius='8px' px='14px'>
              <Text fontSize='sm'>Profile Settings</Text>
            </MenuItem>
            <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius='8px' px='14px'>
              <Text fontSize='sm'>Newsletter Settings</Text>
            </MenuItem>
            <MenuItem
              onClick={handleDelete}
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              color='red.400'
              borderRadius='8px'
              px='14px'>
              <Text fontSize='sm'>Delete Account</Text>
            </MenuItem>
            <MenuItem
              onClick={handleLogout}
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              color='red.400'
              borderRadius='8px'
              px='14px'>
              <Text fontSize='sm'>Log out</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}
